const $retailersList = $('.retailers .accounts');
const $noResults = $('.retailers .no-results');
const $accountsFeedback = $('.retailers .accounts-feedback');
const $accountsFeedbackSpinner = $accountsFeedback.find('.loading-spinner');
const $accountsFeedbackText = $accountsFeedback.find('.text');

(function setupRetailersBlock() {
    $('#retailer-use-location').click(sortByLocation);
})();

function isDevEnvironment() {
    return window.location.hostname === 'easee.test';
}

function sortByLocation() {
    if (!navigator.geolocation) {
        console.error('Geolocation not supported.');
        $accountsFeedbackText.html('Geolocation not supported.')
        $accountsFeedback.addClass('show');
        $accountsFeedbackSpinner.hide();
        return;
    }

    function geoError(error) {
        console.error('Error loading geolocation', error)
        $accountsFeedbackText.html('Geolocation not supported or allowed. Please search manually for your nearest location.', error);
        $accountsFeedback.addClass('show');
        $accountsFeedbackSpinner.hide();
    }

    if (isDevEnvironment()) {
        geoSuccess({coords: {latitude: 58.7343343, longitude: 5.6483699}}, null, 'gps');
    } else {
        $accountsFeedbackText.html('Requesting location access...')
        $accountsFeedback.addClass('show');
        $accountsFeedbackSpinner.show();
        navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
    }
}

function geoSuccess(position, country = null, searchType = 'manual') {
    if (!position || !position.coords) {
        $accountsFeedbackText.html('Geocoordinates not found. Please search manually for your nearest location.');
        $accountsFeedback.addClass('show');
        $accountsFeedbackSpinner.hide();
        return;
    }

    if (!country) {
        reverseGeocodePosition(position);
        return;
    }

    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    let data = new FormData();
    data.append('action', 'retailers_search');
    data.append('country', country || '');
    data.append('lat', lat);
    data.append('lng', lng);

    $accountsFeedbackText.html('Finding retailers...');
    $accountsFeedback.addClass('show');
    $accountsFeedbackSpinner.show();

    $retailersList.html('');
    $retailersList.removeClass('show');

    $.ajax({
        url: easee_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {
            $accountsFeedbackText.html('An error occured: ' + error);
            $accountsFeedback.addClass('show');
            $accountsFeedbackSpinner.hide();
        },
        complete: function (data) {
            $retailersList.html(data.responseText);

            if (data.responseText) {
                $noResults.removeClass('show')
                $retailersList.addClass('show');
            } else {
                $noResults.addClass('show')
                $retailersList.removeClass('show');
            }

            $accountsFeedback.removeClass('show');

            const results = $retailersList.find('.account').length;

            dataLayer.push({
                'event': 'search_installer',
                'search_type': searchType,
                'results': results,
            });

            $retailersList.find('.account h3').each(function(index, el) {
                if (dataLayer) {
                    dataLayer.push({
                        'event': 'view_installer',
                        'installer_name': el.innerText,
                        'position': index + 1
                    });
                }
            });

            $retailersList.find('.account a').click(function(e) {
                const $this = $(this);
                const name = $this.find('h3')[0].innerText;
                const index = $this.parent().index();
                const hasLogo = $this.find('img.logo').length > 0;

                if (dataLayer && name && index > -1) {
                    dataLayer.push({
                        'event': 'select_installer',
                        'installer_name': name,
                        'position': index + 1,
                        'logo': hasLogo ? 'yes' : 'no',
                    });
                }
            })
        }
    });
}

function reverseGeocodePosition(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;

    let data = new FormData();
    data.append('action', 'geocode_position');
    data.append('lat', lat);
    data.append('lng', lng);

    $accountsFeedbackText.html('Getting location...');
    $accountsFeedback.addClass('show');
    $accountsFeedbackSpinner.show();

    $retailersList.html('');
    $retailersList.removeClass('show');

    $.ajax({
        url: easee_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {
            $accountsFeedbackText.html('An error occured: ' + error);
            $accountsFeedback.addClass('show');
            $accountsFeedbackSpinner.hide();
        },
        complete: function (data) {
            const results = data.responseJSON;

            if (results.length === 0) {
                $accountsFeedbackText.html('Not able to find location. Please try searching manually.');
                $accountsFeedback.addClass('show');
                $accountsFeedbackSpinner.hide();
                return;
            }

            const topHit = results[0];
            const topAddress = topHit['formatted_address'];
            const countryComponent = topHit['address_components'].find(c => c.types.indexOf('country') !== -1);

            if (countryComponent) {
                $('#search-city-input').val(topAddress);
                geoSuccess(position, countryComponent['short_name'], 'gps');
            } else {
                $accountsFeedbackText.html('Not able to find location. Please try searching manually.');
                $accountsFeedback.addClass('show');
                $accountsFeedbackSpinner.hide();
            }
        }
    });
}

function initAutocomplete() {
    let $citySearchInput = $('.retailers .search-city').find('input');
    let options = window.googlePlacesCountryCode ? {
        componentRestrictions: {country: window.googlePlacesCountryCode }
    } : {};
    let autocomplete = new google.maps.places.Autocomplete($citySearchInput.get(0), options);
    autocomplete.addListener('place_changed', function() {
        let place = autocomplete.getPlace();
        if (place.geometry) {
            let lat = place.geometry.location.lat();
            let lng = place.geometry.location.lng();
            let country = null;
            let position = {
                coords: {
                    latitude: lat,
                    longitude: lng
                }
            }
            if (place['address_components']) {
                const countryComponent = place['address_components'].find(c => c.types.indexOf('country') !== -1);
                country = countryComponent ? countryComponent['short_name'] : null;
            }

            geoSuccess(position, country);
        }

        let $orderWrapper = $('.order-guide .order-wrapper');
        if($orderWrapper) {
            let $retailersHeight = $(".retailers").outerHeight();
            $orderWrapper.css("min-height", $retailersHeight + 500);
        }
    })
    return autocomplete;
}

window.initAutocomplete = initAutocomplete;
